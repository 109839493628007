// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/tanay/Projects/turkbox/turkbox-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-js-js": () => import("/Users/tanay/Projects/turkbox/turkbox-website/src/pages/404.js.js" /* webpackChunkName: "component---src-pages-404-js-js" */),
  "component---src-pages-about-js": () => import("/Users/tanay/Projects/turkbox/turkbox-website/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/Users/tanay/Projects/turkbox/turkbox-website/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-building-turkbox-js": () => import("/Users/tanay/Projects/turkbox/turkbox-website/src/pages/blog/building-turkbox.js" /* webpackChunkName: "component---src-pages-blog-building-turkbox-js" */),
  "component---src-pages-blog-pilot-with-kl-js": () => import("/Users/tanay/Projects/turkbox/turkbox-website/src/pages/blog/pilot-with-kl.js" /* webpackChunkName: "component---src-pages-blog-pilot-with-kl-js" */),
  "component---src-pages-index-js": () => import("/Users/tanay/Projects/turkbox/turkbox-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pilot-js": () => import("/Users/tanay/Projects/turkbox/turkbox-website/src/pages/pilot.js" /* webpackChunkName: "component---src-pages-pilot-js" */)
}

